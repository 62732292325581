/**
 * http配置
 */
// 引入axios以及element ui中的loading和message组件
import axios from 'axios'
import router from './router'
import { showNotify } from 'vant';
// import { Loading, Message } from 'element-ui'
// 超时时间
axios.defaults.timeout = 10000
// axios.defaults.baseURL = 'http://localhost:7070/sys'
axios.defaults.baseURL = 'https://code.yhu-ocean.top/sys'
// axios.defaults.baseURL = 'https://www.rtre.fun/sys'
// http请求拦截器
// 设置axios的默认请求头
// axios.defaults.headers.common['token'] = localStorage.getItem("token");
axios.interceptors.request.use(config => {
	 // element ui Loading方法
    config.headers.token = localStorage.getItem("token");
	 return config
}, error => {
	 
 return Promise.reject(error)
})

// http响应拦截器
axios.interceptors.response.use(data => {// 响应成功关闭loading
    // console.log(data);
	 if (data.data.code== 50001){
        router.push("/login")
        // alert(data.data.msg)
        showNotify({ message: data.data.msg });
     }else{
        return data.data
     }
	
}, error => {
   // 对响应错误做些什么
   if (error.response && error.response.status === 404) {
      // 处理404错误
      showNotify({ message: '404 Not Found' });
      // console.error('404 Not Found');
    }
   //  console.log(error);
    return error;
})
export default axios
