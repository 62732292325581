<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>

  <van-tabbar route v-if="route.meta.showNavBar">
  <van-tabbar-item replace to="/adduser" icon="setting-o">首页</van-tabbar-item>
  <van-tabbar-item replace to="/editpass" icon="edit">我的</van-tabbar-item>
  <!-- <van-tabbar-item replace to="/updatetoken" icon="upgrade">更新</van-tabbar-item> -->
  <van-tabbar-item replace to="/execTask" icon="chart-trending-o">任务</van-tabbar-item>
  <van-tabbar-item replace to="/userlist" icon="friends-o">用户</van-tabbar-item>

</van-tabbar>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  background-color: aliceblue;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}


</style>
<script>
// import * as Vue from 'vue'
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const active = ref(0);
    const route = useRoute()
    
    return { active,route };
  },
};
</script>