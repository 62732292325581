<template>
     <div  class="container">
        <van-space direction="vertical" fill>
        
        
       
   
<!-- </van-field> -->
    <h1 style="text-align: center;">添加用户</h1>
<van-cell-group inset>
    <van-field
            v-model="platformname"
            is-link
            readonly
            label="平台"
            placeholder="选择平台"
            @click="showPicker = true"
            />
            <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker
                :columns="columns"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
            </van-popup>
  <van-field
    v-model="phoneNumber" type="number"
    center  maxlength="11" 
    clearable  left-icon="phone-circle-o"
    label="手机号"
    placeholder="请输入手机号"
  >
  <template  #button>
    <van-button v-if='phoneNumber.length == 11 && showCountdown == true' ref="smsCode"  slot="button" size="small"><van-count-down :time="time" style="color:#777" @finish='countDownFinish' format=" ss 秒后重试" /></van-button>
    <van-button v-else-if="phoneNumber.length ==11"  @click="sendVerificationCode"  slot="button" size="small">获取验证码</van-button>
    <van-button v-else     disabled    slot="button" size="small">获取验证码</van-button>
            </template>
  </van-field>
  <van-field v-model="code" type="number"  clearable  maxlength="6" label="验证码" left-icon="certificate" placeholder="请输入验证码"></van-field>
</van-cell-group>

        <div style="display: flex;justify-content: center;">
           <van-button type="warning" @click="submit">提交</van-button>
        </div>
        </van-space>
        
        


      </div>
</template>

<script>
  import axios from '../axios';
  import { ref } from 'vue';
  import { showNotify } from 'vant';
  export default {
    setup() {
    //     const show = ref(true);
    // const onInput = (value) => showToast(value);
    // const onDelete = () => showToast('删除');
    
    const columns = [
      { text: 'i茅台', value: 'imt' },
      { text: 'ARCFOX极狐', value: 'jh' },
      { text: '酷狗音乐', value: 'kgmusic' },
      { text: 'Smart汽车', value: 'smart' },
      { text: '东风奕派', value: 'dfmc' },
    ];
    const platformname = ref('ARCFOX极狐');
    const showPicker = ref(false);

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false;
      platformname.value = selectedOptions[0].text;
    };

    return {
      columns,
      onConfirm,
      platformname,
      showPicker,
 
    //   show,
    //   onInput,
    //   onDelete,
    };
  },
    data() {
      return {
        phoneNumber: '',
        code: '',
        // isDataShow: false,
        // isClick: true,
        // time: 60,
        // time: '获取验证码',
        time: 60 * 1000,//倒计时的时间  毫秒级 60秒要*10000
        showCountdown: false//是否显示倒计时
      };
    },
    methods: {
      
        countDownFinish(){//倒计时结束后的方法
            this.showCountdown = false //隐藏倒计时
        },
        
      async sendVerificationCode() {
        // 发送验证码的逻辑
        // 这里只是一个示例，你可以根据实际需求进行实现
        this.showCountdown = true
        var uri = "";
        var name = this.platformname
        switch (name){
            case "i茅台":
                uri = "/imtuser"
                break;
            case "ARCFOX极狐":
                uri = "/jhuser"
                break;
            case "酷狗音乐":
                uri = "/kugouuser"
                break;
            case "Smart汽车":
                uri = "/asdasd"
                break;
            case "东风奕派":
                uri = "/sdasdas"
                break;
        }
        // console.log(name);
            try{
                const response  = await  axios.post(uri + "/SendSMS",{mobile: this.phoneNumber});
            console.log(response);
            
            if (response && response.code == 20000){
                showNotify({ type: 'success', message: response.msg });
            }else if(response.code== 20002){
                this.showCountdown = false
                showNotify({ type: 'danger', message: response.msg  });
            }
            }catch (error){
                showNotify({ type: 'danger', message: error.message})
            }
            // console.log(response);
            // const msg = response.msg 
            // alert(msg)
            
            // alert('验证码已发送');
        // this.verificationCodeEntered = true; // 模拟验证成功
      },
    
      async submit() {
        // 提交表单的逻辑
        // 这里只是一个示例，你可以根据实际需求进行实现
        var uri = "";
        var name = this.platformname
        switch (name){
            case "i茅台":
                uri = "/imtuser"
                break;
            case "ARCFOX极狐":
                uri = "/jhuser"
                break;
            case "酷狗音乐":
                uri = "/kugouuser"
                break;
            case "Smart汽车":
                uri = "/asdasd"
                break;
            case "东风奕派":
                uri = "/sdasdas"
                break;
        }
        // console.log(name);
        try{
            const response  =await axios.put(uri,{mobile: this.phoneNumber,vcode: this.code});
            this.showCountdown = false
        // console.log(response);
        if (response && response.code == 20000){
                this.phoneNumber = '';
                this.code= '';
                showNotify({ type: 'success', message: response.msg });
            }else if(response.code== 20002){
                showNotify({ type: 'danger', message: response.msg  });
            }
        }catch (error){
            showNotify({ type: 'danger', message: error.message})
        }
        
        
        // alert('表单已提交');
        // 进行页面跳转或其他操作
      },
    },
  };


</script>

<style scoped>
.container {
  /* max-width: 300px; */
  /* margin: 0 auto;
  padding: 5px;
  text-align: center; */
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}
/* van-field {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
</style>