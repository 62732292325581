<template>
  
<van-nav-bar title="用户列表" />

<van-tabs v-model:active="active">
  <van-tab title="极狐用户">
                <van-notice-bar
              left-icon="volume-o" scrollable 
              text="右侧开关开启代表今日已签到，关闭代表今日未签到。"
            />
            <!-- <van-search v-model="phone" placeholder="请输入搜索的手机号" /> -->
            <form action="/">
              <van-search
                v-model="phone"
                show-action
                placeholder="请输入搜索的手机号"
                @search="onSearch"
                @cancel="onCancel" clearable
                @blur="onBlur"

              />
            </form>
            <van-list
              v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad" 
            >
              
              <van-cell center  v-for="item in list" :key="item" >
              <template #value >
                <van-switch v-model="item.signInStatus" disabled  />
              </template>
              <template #title>
                <span class="custom-title">{{displayTitle(item)}}</span>
              </template>
            </van-cell>
            </van-list>


  </van-tab>
  <van-tab title="酷狗用户">
    
    <van-notice-bar
              left-icon="volume-o" scrollable 
              text="记录从左至右，分别代表用户手机号-今日收入-总收入(总收入指一共获得过的酷狗币)"
            />
            <!-- <van-search v-model="phone" placeholder="请输入搜索的手机号" /> -->
            <!-- <form action="/">
              <van-search
                v-model="kgphone"
                show-action
                placeholder="请输入搜索的手机号"
                @search="onSearch"
                @cancel="onCancel" clearable
                @blur="onBlur"

              />
            </form> -->
            <van-list
              v-model:loading="loading"
              :finished="finished1"
              finished-text="没有更多了"
              @load="onLoad1"
            >
              
              <van-cell  v-for="item in kglist" :key="item" >
               
              <template #title>
                <!-- <span class="custom-title"> -->
                  <van-row>
                    <van-col span="8">{{ item.kugouuser.mobile }} </van-col>
                    <van-col span="8"> 今日收入：{{ item.tadayIncome }}</van-col>
                    <van-col span="8">总收入：{{ item.totalIncome }}</van-col>
                  </van-row>
                 <!-- - - </span> -->
              </template>
            </van-cell>
            </van-list>



  </van-tab>
  
</van-tabs>





</template>

<script>

import { ref } from 'vue';
import axios from '../axios';
import { showToast,showNotify } from 'vant';

export default {
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const finished1 = ref(false);
    const phone =ref('')
    var pageNo = 0;
    var pageNum = 0;
    const signInStateChecked= ref(false)
    const kglist = ref([]);

    const onSearch = (mobile) => {
        pageNo = 0
        finished.value =false

        phone.value = mobile
        onLoad()
    };

    const onCancel = () => showToast('取消');
    const onBlur = () => {
        onLoad()
    }

    const onLoad = () => {
      // 异步更新数据
        loading.value = true
        pageNo  = pageNo + 1
        axios.get(`/jhuser/list?pageNo=${pageNo}&pageSize=30&mobile=${phone.value}`).then(res =>{
            if (res.code == 20002){
              showNotify({ type: 'danger', message: res.msg  });
              return
            }
            
            if (pageNo==1){
             
                list.value = res.data.rows
                
            }else{
                list.value = [...list.value,...res.data.rows]
            }
            if (list.value.length >=  res.data.total){
  
                finished.value = true;
            }
            loading.value = false;
        })
        
        
        
    }
    const onLoad1 = () => {
      // 异步更新数据
        loading.value = true
        pageNum  = pageNum + 1
        axios.get(`/kugouuser/list?pageNo=${pageNum}&pageSize=30`).then(res =>{
            if (res.code == 20002){
              showNotify({ type: 'danger', message: res.msg  });
              return
            }
            
            if (pageNum==1){
             
              kglist.value = res.data.rows
                
            }else{
              kglist.value = [...kglist.value,...res.data.rows]
            }
            if (kglist.value.length >=  res.data.total){
  
                finished1.value = true;
            }
            loading.value = false;
        })
        
        
        
    }
    const displayTitle = (item) => {
    
      // console.log(signInStateChecked);

      return `${item.jhuser.mobile} - ${item.balanceIntegral}积分`;
    }
    

//     watch(()=> mobile,()=>{
   
//    if (mobile.value==true){
//     console.log(123123123);
//    }else{
    
//    }
// },{deep: true})
    return {
      list,
      onLoad,
      loading,
      finished,
      phone,
      onSearch,
      onCancel,
      onBlur,
      displayTitle,
      signInStateChecked,
      finished1,
      onLoad1,
      kglist,



    };
  },
};









</script>

<style>
.custom-title{
  width: 500px;
}

</style>
