<template>
<van-nav-bar title="计划任务" />
        <van-tabs v-model:active="active">
          <van-tab title="手动执行">
            <van-space direction="vertical" fill style="margin-top: 10px;">
            <!-- <h1>任务计划</h1> -->
            <p class="hlightText" style="margin: 10px;">根据手机号执行任务</p>
          <van-cell-group inset>
            <van-field
                    v-model="platformname"
                    is-link
                    readonly
                    label="平台"
                    placeholder="选择平台"
                    @click="showPicker = true"
                    />
                    <van-popup v-model:show="showPicker" round position="bottom">
                    <van-picker
                        :columns="columns"
                        @cancel="showPicker = false"
                        @confirm="onConfirm"
                    />
                    </van-popup>
          <van-field
            v-model="phoneNumber" type="number"
            center  maxlength="11" 
            clearable  left-icon="phone-circle-o"
            label="手机号"
            placeholder="请输入手机号"
          >
          </van-field>

          </van-cell-group>

          <van-row justify="center">
            
            <van-col span="12"> 
              <div style="display: flex;justify-content: center;">
              <van-button type="warning" @click="submitSignIn" style="margin-right: 10px;">签到</van-button>    <van-button type="warning" @click="submitPost">发帖</van-button>
            </div>
            </van-col>

          
           
          </van-row>
        </van-space>

          </van-tab>
          <van-tab title="设置">
            <p class="hlightText" style="margin: 10px;">设置签到执行时间</p>
          <van-field
            v-model="signinResult"
            is-link
            readonly
            name="datePicker"
            label="时间选择"
            placeholder="点击选择时间"
            @click="showPicker1 = true"
            
          />
          <van-popup v-model:show="showPicker1" position="bottom"  >
            <van-time-picker :columns-type="columnsSignInType" v-model="columnsSignInTime" title="选择时间" @cancel="showPicker1 = false" @confirm="onConfirm1"/>
          </van-popup>
          <!-- <van-button type="warning" @click="submitTaskSigninTime">提交</van-button> -->


          <van-cell center title="当前签到状态">
            <template #title>
              当前签到时间：{{ signInTime }}
            </template>
            <template #value>
              <van-switch v-model="signInChecked" />
            </template>
          </van-cell>


          <p class="hlightText" style="margin: 10px;">设置发帖执行时间</p>
          <van-field
            v-model="postResult"
            is-link
            readonly
            name="datePicker"
            label="时间选择"
            placeholder="点击选择时间"
            @click="showPickerPost = true"
            
          />
          <van-popup v-model:show="showPickerPost" position="bottom"  >
            <van-time-picker :columns-type="columnsType" v-model="currentTime" title="选择时间" @cancel="showPickerPost = false" @confirm="onConfirmPost"/>
          </van-popup>


        <van-cell center>
          <template #title>
            当前发帖时间: {{ postTime }}
          </template>
          <template #value>
            <van-switch v-model="postChecked" />
          </template>
        </van-cell>


          </van-tab>
        
        </van-tabs>

   
       
       

      
  
<!-- </van-field> -->



<!--         
       </van-space>
       -->
        
        

<!-- <van-switch v-model="checked" /> -->
     <!-- </div> -->
</template>

<script>
 import axios from '../axios';
 import { ref,watch } from 'vue';
 import { showNotify } from 'vant';
 export default {
   setup() {
    
   const signinResult = ref('');
   const postResult = ref('');
    const showPicker1 = ref(false);
    const showPickerPost = ref(false);
    const onConfirm1 = ({ selectedValues }) => {
      showPicker1.value = false;
      signinResult.value = selectedValues.join('/');
    };
    const onConfirmPost = ({ selectedValues }) => {
      showPickerPost.value = false;
      postResult.value = selectedValues.join('/');
    };
    const currentTime = ref(['6','00']);
    const columnsType = ['hour', 'minute', 'second'];
    
    const columnsSignInTime = ref(['6','00']);
    const columnsSignInType = ['hour', 'minute', 'second'];

   const columns = [
     { text: '广汽传祺', value: 'gqcq' },
     { text: 'ARCFOX极狐', value: 'jh' },
     { text: '酷狗音乐', value: 'kgmusic' },
     { text: 'Smart汽车', value: 'smart' },
     { text: '东风奕派', value: 'dfmc' },
   ];
   const platformname = ref('ARCFOX极狐');
   const showPicker = ref(false);

   const onConfirm = ({ selectedOptions }) => {
     showPicker.value = false;
     platformname.value = selectedOptions[0].text;
   };

  //  开关
  const signInChecked = ref(false);
  const postChecked = ref(false);
  const postTime = ref(localStorage.getItem("postTime"));
  const signInTime = ref(localStorage.getItem("signInTime"));

   // 设置签到时间的方法
   async function submitTaskSigninTime(){
            var uri = "";
              var name = platformname.value
              switch (name){
                  case "广汽传祺":
                      uri = "/gqcquser"
                      break;
                  case "ARCFOX极狐":
                      uri = "/jhuser"
                      break;
                  case "酷狗音乐":
                      uri = "/kugouuser/finishTask"
                      break;
                  case "Smart汽车":
                      uri = "/asdasd"
                      break;
                  case "东风奕派":
                      uri = "/sdasdas"
                      break;
              }
        //  console.log(this.result);
          try{
            if (signinResult.value == "" || signinResult.value == null || signinResult.value == undefined ){
              // showNotify({ type: 'danger', message: "请选择一个时间"})
              return
            }
            const response  =await axios.post("/task/signinTime",{tasktime: signinResult.value});
        // console.log(response);
            if (response && response.code == 20000){
              localStorage.setItem('signInTime', response.data.signInTime);
                showNotify({ type: 'success', message: response.msg });
                signInTime.value = localStorage.getItem("signInTime")
            }else if(response.code== 20002){
                showNotify({ type: 'danger', message: response.msg  });
            }
        }catch (error){
            showNotify({ type: 'danger', message: error.message})
        }
      }

      // 停止签到时间的方法
      async function stopTaskSigninTime(){
        
        //  console.log(this.result);
          try{
            const response  =await axios.post("/task/stop/1099cc50-4f54-47d7-b24a-43e85b804868");
        // console.log(response);
            if (response && response.code == 20000){
              
                showNotify({ type: 'success', message: response.msg });
            }else if(response.code== 20002){
                showNotify({ type: 'danger', message: response.msg  });
            }
        }catch (error){
            showNotify({ type: 'danger', message: error.message})
        }
      }


    // 设置发帖时间的方法
    async function submitTaskPostTime(){
        
        //  console.log(this.result);
          try{
            if (postResult.value == "" || postResult.value == null || postResult.value == undefined ){
              // showNotify({ type: 'danger', message: "请选择一个时间"})
              return
            }
            const response  =await axios.post("/task/postTime",{tasktime: postResult.value});
          
        // console.log(response);
            if (response && response.code == 20000){
            
              localStorage.setItem('postTime', response.data.postTime);
              
              showNotify({ type: 'success', message: response.msg });
              postTime.value = localStorage.getItem("postTime")
            }else if(response.code== 20002){
                showNotify({ type: 'danger', message: response.msg  });
            }
        }catch (error){
            showNotify({ type: 'danger', message: error.message})
        }
      }

      // 停止发帖任务的方法
      async function stopTaskPostTime(){
              
        //  console.log(this.result);
          try{
            const response  =await axios.post(`/task/stop/f8feaefb-8915-4421-b54c-1a6339ef6f1c`);
          
        // console.log(response);
            if (response && response.code == 20000){
                
                showNotify({ type: 'success', message: response.msg });
            }else if(response.code== 20002){
                showNotify({ type: 'danger', message: response.msg  });
            }
        }catch (error){
            showNotify({ type: 'danger', message: error.message})
        }
      }

      // 检测任务执行状态
      async function checkTaskStatus(typeValue){
        try{
            const response  =await axios.post(`/task/status`,{type: typeValue});
          
        // console.log(response);
            if (response && response.code == 20000){
              if (typeValue == "post"){
                postChecked.value = response.data
              }else if (typeValue == "signin"){
                signInChecked.value = response.data
              }
                
                
            }else if(response.code== 20002){
                showNotify({ type: 'danger', message: response.msg  });
            }
        }catch (error){
            showNotify({ type: 'danger', message: error.message})
        }

      }
      checkTaskStatus("post")
      checkTaskStatus("signin")
      watch(()=> postChecked,()=>{
   
       if (postChecked.value==true){
        submitTaskPostTime()
      
       }else{
        stopTaskPostTime()


       }
  },{deep: true})


  watch(()=> signInChecked,()=>{
   
    if (signInChecked.value==true){
        submitTaskSigninTime()
    
    }else{
      stopTaskSigninTime()
    }
  },{deep: true})

   return {
     columns,
     onConfirm,
     platformname,
     showPicker,
     currentTime,
     columnsType,
     signinResult,
     postResult,
      onConfirm1,
      showPicker1,
      signInChecked,
      showPickerPost,
      onConfirmPost,
      postChecked,
      postTime,
      signInTime,
      columnsSignInTime,
      columnsSignInType,

   };
 },
   data() {
     return {
       phoneNumber: '',
    
       // isDataShow: false,
       // isClick: true,
       // time: 60,
       // time: '获取验证码',
       time: 60 * 1000,//倒计时的时间  毫秒级 60秒要*10000
       showCountdown: false//是否显示倒计时
     };
   },
   methods: {
     
      

       // async getSmsCode(){
       //     //这里只是简单的显示倒计时逻辑获取验证码需要具体的接口
       //     await  axios.post("/gqcquser/SendSMS",{mobile: this.phoneNumber,});
       

       // },
       countDownFinish(){//倒计时结束后的方法
           this.showCountdown = false //隐藏倒计时
       },
       
     async submitSignIn() {
       // 提交表单的逻辑
       // 这里只是一个示例，你可以根据实际需求进行实现
       var uri = "";
       var name = this.platformname
       switch (name){
           case "ARCFOX极狐":
               uri = "/jhuser/signin"
               break;
           case "酷狗音乐":
               uri = "/kugouuser/finishTask"
               break;
           case "Smart汽车":
               uri = "/asdasd"
               break;
           case "东风奕派":
               uri = "/sdasdas"
               break;
       }
       // console.log(name);
       try{
           const response  =await axios.post(uri,{mobile: this.phoneNumber,vcode: this.code});
           this.showCountdown = false
       // console.log(response);
       if (response && response.code == 20000){
              
               showNotify({ type: 'success', message: response.msg });
           }else if(response.code== 20002){
               showNotify({ type: 'danger', message: response.msg  });
           }
       }catch (error){
           showNotify({ type: 'danger', message: error.message})
       }
       
       
       // alert('表单已提交');
       // 进行页面跳转或其他操作
     },


    async submitPost(){
  // 提交表单的逻辑
       // 这里只是一个示例，你可以根据实际需求进行实现
       var uri = "";
       var name = this.platformname
       switch (name){
           case "ARCFOX极狐":
               uri = "/jhuser/submitpost"
               break;
           case "酷狗音乐":
               uri = "/kugouuser"
               break;
           case "Smart汽车":
               uri = "/asdasd"
               break;
           case "东风奕派":
               uri = "/sdasdas"
               break;
       }
       // console.log(name);
       try{
           const response  =await axios.post(uri,{mobile: this.phoneNumber,vcode: this.code});
           this.showCountdown = false
       // console.log(response);
       if (response && response.code == 20000){
            
               
               showNotify({ type: 'success', message: response.msg });
           }else if(response.code== 20002){
               showNotify({ type: 'danger', message: response.msg  });
           }
       }catch (error){
           showNotify({ type: 'danger', message: error.message})
       }
       
     },
   },
 };


</script>

<style scoped>
.container {
 /* max-width: 300px; */
 /* margin: 0 auto;
 padding: 5px;
 text-align: center; */
 display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   height: 100vh;
}
.hlightText {
  color: red;
  margin: 0;
  padding: 5px;
}
</style>