import { createRouter,createWebHashHistory  } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import AddUser from '../views/AddUser.vue'
import EditPass from '../views/EditPass.vue'
import TaskView from '../views/TaskView.vue'
import jhuserList from '../views/jhuserList.vue'
const routes = [
  {
    path: '/',
    name: 'dashboard',
    redirect: '/login',
    meta:{
      showNavBar:false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta:{
      showNavBar:false
    }
  },
  // {
  //   path: '/updatetoken',
  //   name: 'updatetoken',
  //   component: UpdateView,
  //   meta:{
  //     requireAuth: true,
  //     showNavBar:true
  //   }
  // },
  {
    path: '/execTask',
    name: 'execTask',
    component: TaskView,
    meta:{
      requireAuth: true,
      showNavBar:true
    }
  },
  {
    path: '/userlist',
    name: 'userlist',
    component: jhuserList,
    meta:{
      requireAuth: true,
      showNavBar:true
    }
  },

  // {
  //   path: '/index',
  //   name: 'index',
  //   component: IndexView,
  //   meta:{
  //     showNavBar:true
  //   }
  // },
  {
    path: '/adduser',
    name: 'adduser',
    meta: {
      requireAuth: true,
      showNavBar:true
    },
    component: AddUser
  },
  {
      path: '/editpass',
      name: 'editpass',
      meta: {
        requireAuth: true,
        showNavBar:true
      },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EditPass
  }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
export default router
