<template>
    <!-- <div  class="container"> -->
        <van-nav-bar title="极狐专用" />
       
        <van-tabs v-model:active="active">
  <van-tab title="修改密码">

    <van-space direction="vertical" fill>
<!-- </van-field> -->
<!-- <h1>修改密码</h1> -->
<van-cell-group inset style="margin-top: 10px;">
   <van-field
           v-model="platformname"
           is-link
           readonly
           label="平台"
           placeholder="选择平台"
           @click="showPicker = true"
           />
           <van-popup v-model:show="showPicker" round position="bottom">
           <van-picker
               :columns="columns"
               @cancel="showPicker = false"
               @confirm="onConfirm"
           />
           </van-popup>
 <van-field
   v-model="phoneNumber" type="number"
   center  maxlength="11" 
   clearable  left-icon="phone-circle-o"
   label="手机号"
   placeholder="请输入手机号"
 >
 <template  #button>
   <van-button v-if='phoneNumber.length == 11 && showCountdown == true' ref="smsCode"  slot="button" size="small"><van-count-down :time="time" style="color:#777" @finish='countDownFinish' format=" ss 秒后重试" /></van-button>
   <van-button v-else-if="phoneNumber.length ==11"  @click="sendVerificationCode"  slot="button" size="small">获取验证码</van-button>
   <van-button v-else     disabled    slot="button" size="small">获取验证码</van-button>
           </template>
 </van-field>
 <van-field v-model="code" type="number"  clearable  maxlength="6" label="验证码" left-icon="certificate" placeholder="请输入验证码"></van-field>

 <van-field v-model="pass"  clearable maxlength="10" type="password"  label="密码" left-icon="passed" placeholder="请输入密码"></van-field>
</van-cell-group>

        <p class="hlightText">一般情况下不需要修改密码。</p>
        <p class="hlightText">提交成功后可查看修改后的密码。</p>
        <div style="display: flex;justify-content: center;">
            <van-button type="warning" @click="submitUpdatePass">提交修改</van-button>
            </div>
       </van-space>
  </van-tab>
  <van-tab title="更新token">
    
    <!-- <h1>更新token</h1> -->
<van-cell-group inset style="margin-top: 10px;">
   <van-field
           v-model="platformname1"
           is-link
           readonly
           label="平台"
           placeholder="选择平台"
           @click="showPicker = true"
           />
           <van-popup v-model:show="showPicker" round position="bottom">
           <van-picker
               :columns="columns"
               @cancel="showPicker = false"
               @confirm="onConfirm1"
           />
           </van-popup>
 <van-field
   v-model="phoneNumber1" type="number"
   center  maxlength="11" 
   clearable  left-icon="phone-circle-o"
   label="手机号"
   placeholder="请输入手机号"
 >
 </van-field>
</van-cell-group>
      <p class="hlightText">只需输入手机号即可提交</p>
      <p class="hlightText">提交后根据默认密码更新token</p>
      <div style="display: flex;justify-content: center;">
        <van-button type="warning" @click="submit">提交</van-button>
       </div>


  </van-tab>

</van-tabs>
      

       

       


     <!-- </div> -->
</template>

<script>
 import axios from '../axios';
  import { ref } from 'vue';
  import { showNotify } from 'vant';

export default {
    setup() {
    //     const show = ref(true);
    // const onInput = (value) => showToast(value);
    // const onDelete = () => showToast('删除');

    const columns = [
      { text: '广汽传祺', value: 'gqcq' },
      { text: 'ARCFOX极狐', value: 'jh' },
      { text: '酷狗音乐', value: 'kgmusic' },
      { text: 'Smart汽车', value: 'smart' },
      { text: '东风奕派', value: 'dfmc' },
    ];
    const platformname = ref('ARCFOX极狐');
    const platformname1 = ref('ARCFOX极狐');
    const showPicker = ref(false);

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false;
      platformname.value = selectedOptions[0].text;
    };
    const onConfirm1 = ({ selectedOptions }) => {
      showPicker.value = false;
      platformname1.value = selectedOptions[0].text;
    };
    const pass = "123456";
    return {
      columns,
      onConfirm,
      platformname,
      showPicker,
      pass,
      platformname1,
      onConfirm1,

    //   show,
    //   onInput,
    //   onDelete,
    };
  },
    data() {
        return {
            phoneNumber: '',
            phoneNumber1: '',
            code: '',
            // isDataShow: false,
            // isClick: true,
            // time: 60,
            // time: '获取验证码',
            time: 60 * 1000,//倒计时的时间  毫秒级 60秒要*10000
            showCountdown: false,//是否显示倒计时
            pass: "1ue.com#@!",

        };
        },

    methods: {
       

       // async getSmsCode(){
       //     //这里只是简单的显示倒计时逻辑获取验证码需要具体的接口
       //     await  axios.post("/gqcquser/SendSMS",{mobile: this.phoneNumber,});
       

       // },
       countDownFinish(){//倒计时结束后的方法
           this.showCountdown = false //隐藏倒计时
       },
       
     async sendVerificationCode() {
       // 发送验证码的逻辑
       // 这里只是一个示例，你可以根据实际需求进行实现
       this.showCountdown = true
       var uri = "";
       var name = this.platformname
       switch (name){
           case "广汽传祺":
               uri = "/gqcquser"
               break;
           case "ARCFOX极狐":
               uri = "/jhuser"
               break;
           case "酷狗音乐":
               uri = "/kugouuser"
               break;
           case "Smart汽车":
               uri = "/asdasd"
               break;
           case "东风奕派":
               uri = "/sdasdas"
               break;
       }
       // console.log(name);
           try{
               const response  = await  axios.post(uri + "/SendEditPassSMS",{mobile: this.phoneNumber});
           console.log(response);
           
           if (response && response.code == 20000){
               showNotify({ type: 'success', message: response.msg });
           }else if(response.code== 20002){
               this.showCountdown = false
               showNotify({ type: 'danger', message: response.msg  });
           }
           }catch (error){
               showNotify({ type: 'danger', message: error.message})
           }
           // console.log(response);
           // const msg = response.msg 
           // alert(msg)
           
           // alert('验证码已发送');
       // this.verificationCodeEntered = true; // 模拟验证成功
     },
     async submitupdate(){
       try{
           const response  =await axios.post("/jhuser/login");
           this.showCountdown = false
       // console.log(response);
           if (response && response.code == 20000){
                   this.phoneNumber = '';
                   this.code= '';
                   showNotify({ type: 'success', message: response.msg });
               }else if(response.code== 20002){
                   showNotify({ type: 'danger', message: response.msg  });
               }
           }catch (error){
               showNotify({ type: 'danger', message: error.message})
           }
     },
     async submit() {
       // 提交表单的逻辑
       // 这里只是一个示例，你可以根据实际需求进行实现
       var uri = "";
       var name = this.platformname1
       switch (name){
           case "广汽传祺":
               uri = "/gqcquser"
               break;
           case "ARCFOX极狐":
               uri = "/jhuser/update"
               break;
           case "酷狗音乐":
               uri = "/kugouuser"
               break;
           case "Smart汽车":
               uri = "/asdasd"
               break;
           case "东风奕派":
               uri = "/sdasdas"
               break;
       }
       // console.log(name);
       try{
           const response  =await axios.post(uri,{mobile: this.phoneNumber1});
           this.showCountdown = false
       // console.log(response);
       if (response && response.code == 20000){
               this.phoneNumber1 = '';
              
               showNotify({ type: 'success', message: response.msg });
           }else if(response.code== 20002){
               showNotify({ type: 'danger', message: response.msg  });
           }
       }catch (error){
           showNotify({ type: 'danger', message: error.message})
       }
       
       
       // alert('表单已提交');
       // 进行页面跳转或其他操作
     },
     async submitUpdatePass() {
       // 提交表单的逻辑
       // 这里只是一个示例，你可以根据实际需求进行实现
       var uri = "";
       var name = this.platformname
       switch (name){
           case "广汽传祺":
               uri = "/gqcquser"
               break;
           case "ARCFOX极狐":
               uri = "/jhuser/changepass"
               break;
           case "酷狗音乐":
               uri = "/kugouuser"
               break;
           case "Smart汽车":
               uri = "/asdasd"
               break;
           case "东风奕派":
               uri = "/sdasdas"
               break;
       }
       // console.log(name);
       try{
           const response  =await axios.post(uri,{mobile: this.phoneNumber});
           this.showCountdown = false
       // console.log(response);
       if (response && response.code == 20000){
               this.phoneNumber = '';
               this.code= '';
               showNotify({ type: 'success', message: response.msg });
           }else if(response.code== 20002){
               showNotify({ type: 'danger', message: response.msg  });
           }
       }catch (error){
           showNotify({ type: 'danger', message: error.message})
       }
       
       
       // alert('表单已提交');
       // 进行页面跳转或其他操作
     },


   },
 };


</script>

<style scoped>
.container {
 /* max-width: 300px; */
 /* margin: 0 auto;
 padding: 5px;
 text-align: center; */
 display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   height: 100vh;
}
.hlightText {
  color: red;
  margin: 0;
  padding: 5px;
  text-align: center;
}
</style>







