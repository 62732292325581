<template>
    <div>
      <div  class="container">
        <van-space direction="vertical" fill>
  <!-- 可以使用 CellGroup 作为容器 -->
  <van-cell-group inset>
            <van-field v-model="secret" type="password" label="密码" @keyup.enter='checkPassword' />
        </van-cell-group> 

        <div style="display: flex;justify-content: center;">
        <van-button type="primary" @click="checkPassword">Login</van-button>
      </div>
</van-space>
       
      </div>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { showNotify } from 'vant';

  export default {
    data() {
      return {
        secret: '',
        
      };
    },
    methods: {
      async checkPassword() {
        try {
          // 发送密码验证请求到后端
          const response = await axios.post('/login', { secret: this.secret });
        //   console.log(response);
          if (response.code == 20000) {


            this.passwordEntered = true;
        
            // 登录成功处理
            const token = response.data.token;

            // 将token保存到本地存储或Vuex等状态管理工具中
            // 例如，使用localStorage保存token
            localStorage.setItem('token', token);

            
            this.$router.push("/addUser")
        } else {
            showNotify({ type: 'danger', message: response.msg  });
          }
        } catch (error) {
          console.error(error);
          alert('请求发生错误');
        }
      },
    
    },
  };
  </script>
  
  <style scoped>
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    
  }

  </style>