import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Field, CellGroup,Button,Space,Tab, Tabs,Picker,Popup,CountDown,Notify,Tabbar,NoticeBar, TabbarItem,PasswordInput,Col, Row,TimePicker ,Toast,Switch,Cell,List,Search,NavBar} from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
// import axios from 'axios'


//to表示目标路由 from从什么地方来的路由、对象
//next 进行下一个路由 需要放行
// router.beforeEach((to, from, next) => { 
//     if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
//         var token=window.localStorage.getItem('token');
//         console.log(token)
//         if (token) { // 查询本地存储信息是否已经登陆
//           next();
//         } 
//         else {
//         next({  //不存在，回退到一个页面，例如登录页面
//             path: '/login',
//             query: {redirect: to.fullPath} // 将跳转的路由path作为参数，登录成功后跳转到该路由
//         })
//         }
//     }
//     else {
//         next();
//     }
//     })

  
createApp(App).use(store).use(router).use(Field).use(Tab).use(Tabs).use(Notify).use(TimePicker).use(NoticeBar).use(Cell).use(List).use(NavBar).use(Search).use(Switch).use(Toast).use(Button).use(Tabbar).use(Col).use(Row).use(PasswordInput).use(TabbarItem).use(Popup).use(CountDown).use(Picker).use(Space).use(CellGroup).mount('#app')
